<template>
    <div class="login-container">
      <h2>Вход в административную панель</h2>
      <input v-model="password" type="password" placeholder="Введите пароль" />
      <button @click="checkPassword">Войти</button>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCookie } from '../../utils/cookies';
  
  const correctPassword = '91b28JzS35!';
  const password = ref('');
  const errorMessage = ref('');
  const router = useRouter();
  
  // Проверяем введенный пароль
  const checkPassword = () => {
    if (password.value === correctPassword) {
      setCookie('admin_access', 'true', 1); // Храним в куки на 1 день
      router.push('/admin'); // Перенаправляем на админку
    } else {
      errorMessage.value = 'Неверный пароль. Попробуйте снова.';
    }
  };
  </script>
  
  <style scoped>
  .login-container {
    text-align: center;
    margin-top: 50px;
  }
  .error {
    color: red;
  }
  </style>
  