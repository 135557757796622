<template>
    <div id="admin" class="grid">
      <main class="col-offset-2 col-8">
        <h2>Административная панель</h2>
        <p>Добро пожаловать <b>Administrator</b>. Здесь вы можете создавать и редактировать содержимое опросов</p>
        <router-view></router-view>
      </main>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AdminLayout',
  };
  </script>