<template>
    <div>
      <h3>Справочники</h3>
      <ul class="ref-list" v-if="referenceFiles.length">
        <li v-for="file in referenceFiles" :key="file.id">
        <div class="flex justify-content-between align-items-center">
        <div>
          <p class="m-0" v-if="!file.name">{{ file.file }}</p>
          <p class="m-0" style="vertical-align: middle;" v-else>{{ file.name }} <span class="subtext">({{ file.file }})</span></p>
          <div class="column-list">
            <span class="m-0">Колонки:</span>
            <span v-for="column in file.columns" :key="column.name">{{ column.name }}</span>
          </div>
        </div>
          <div class="flex justify-content-between">
          <Button  @click="openEditNameDialog(file.id, file.name)" severity="link" icon="fa fa-edit" />
          <Button @click="openDeleteDialog(file.id)" severity="link" icon="fa fa-trash" class="delete-action" />
        </div>
        </div>
        </li>
      </ul>
      <p v-else>Справочников нет.</p>
      <div class="upload-form">

      </div>
      <input type="file" @change="onFileChange">
      <Button label="Upload" @click="uploadFile" severity="primary">Загрузить</Button>
  
      <!-- Модальное окно для редактирования имени файла -->
      <Dialog header="Изменить имя файла" v-model:visible="isEditDialogVisible">
        <InputText class="mb-3" style="width:100%;" v-model="newFileName" placeholder="Введите новое имя" />
        <div class="flex justify-content-between">
        <Button label="Отмена" @click="isEditDialogVisible = false" severity="secondary" />
        <Button label="Сохранить" @click="saveFileName" severity="primary" />
        </div>
      </Dialog>
  
      <!-- Модальное окно для подтверждения удаления -->
      <Dialog header="Подтверждение удаления" v-model:visible="isDeleteDialogVisible">
        <p>Вы уверены, что хотите удалить этот файл?</p>
        <Button label="Удалить" @click="confirmDeleteFile" />
        <Button label="Отмена" @click="isDeleteDialogVisible = false" />
      </Dialog>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  import { uploadReferenceFile, getReferenceFiles, updateReferenceFileName, deleteReferenceFile } from '../../services/apiService';
  import Button from 'primevue/button';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  
  const selectedFile = ref(null);
  const fileName = ref('');
  const referenceFiles = ref([]);
  const fileEditName = ref({});
  const isEditDialogVisible = ref(false);
  const isDeleteDialogVisible = ref(false);
  const currentFileId = ref(null);
  const newFileName = ref('');
  
  const onFileChange = (e) => {
    selectedFile.value = e.target.files[0];
  };
  
  const uploadFile = async () => {
    if (!selectedFile.value) {
      alert("Выберите файл для загрузки.");
      return;
    }
    const formData = new FormData();
    formData.append('file', selectedFile.value);
    formData.append('name', fileName.value);
  
    try {
      await uploadReferenceFile(formData);
      fetchReferenceFiles();
    } catch (error) {
      console.error("Ошибка загрузки файла:", error);
    }
  };
  
  const fetchReferenceFiles = async () => {
    try {
      const response = await getReferenceFiles();
      referenceFiles.value = response.data;
      fileEditName.value = response.data.reduce((acc, file) => {
        acc[file.id] = file.name;
        return acc;
      }, {});
    } catch (error) {
      console.error("Ошибка получения списка справочников:", error);
    }
  };
  
  const openEditNameDialog = (fileId, currentName) => {
    currentFileId.value = fileId;
    newFileName.value = currentName || '';
    isEditDialogVisible.value = true;
  };
  
  const saveFileName = async () => {
    try {
      await updateReferenceFileName(currentFileId.value, newFileName.value);
      fetchReferenceFiles();
      isEditDialogVisible.value = false;
    } catch (error) {
      console.error("Ошибка обновления имени файла:", error);
    }
  };
  
  const openDeleteDialog = (fileId) => {
    currentFileId.value = fileId;
    isDeleteDialogVisible.value = true;
  };
  
  const confirmDeleteFile = async () => {
    try {
      await deleteReferenceFile(currentFileId.value);
      fetchReferenceFiles();
      isDeleteDialogVisible.value = false;
    } catch (error) {
      console.error("Ошибка удаления файла:", error);
    }
  };
  
  onMounted(() => {
    fetchReferenceFiles();
  });
  </script>
  
  <style scoped>
  .ref-list, .column-list {
    list-style: none;
    padding: 0;
    max-width: 100%;
  }
  .subtext {
    font-size: 0.7rem!important;
    vertical-align: middle;
    color: #6c757d;
  }
  .ref-list > li {
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
  }
  .column-list span {
    font-size: 0.7rem !important;
    margin-right: 10px;
    color: #6c757d;
    display: inline-block;
  }
  .delete-action {
  color: #dc3545;
}
  </style>
  