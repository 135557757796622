<template>
  <div class="custom-rating">
    <div
      v-for="value in stars"
      :key="value"
      class="rating-circle"
      :class="{ 
        'active': value <= selectedValue,
        'disabled': isNone
      }"
      :style="{
        pointerEvents: isNone ? 'none' : 'auto',
        opacity: isNone ? 0.4 : 1
      }"
      @click="!isNone && handleClick(value)"
    >
      {{ value }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps({
  modelValue: Number,
  stars: Number,
  isNone: Boolean,
});

const emit = defineEmits(['update:modelValue']);

const selectedValue = ref(props.modelValue);

// Handle click event to update value
const handleClick = (value) => {
  selectedValue.value = value;
  emit('update:modelValue', value);
};

// Watch for changes to props and update selectedValue
watch(() => props.modelValue, (newValue) => {
  selectedValue.value = newValue;
});
</script>

<style scoped>
.custom-rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.rating-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px; /* Width of the circle */
  height: 17px; /* Height of the circle */
  border-radius: 50%; /* Makes it a circle */
  background-color: #f0f0f0; /* Light background color */
  color: #333; /* Text color */
  font-size: 10px; /* Font size for the number */
  border: 1px solid #cbd5e1; /* Border color */
  cursor: pointer; /* Pointer cursor on hover */
  user-select: none; /* Prevent text selection */
}

.rating-circle.active {
  background-color: #2d588b; /* Active color */
  color: white; /* Text color when active */
  border: 2px solid #2d588b; /* Border color when active */
}

.rating-circle.disabled {
  background-color: #e0e0e0; /* Light grey background for disabled */
  color: #9e9e9e; /* Grey text color for disabled */
  border-color: transparent!important;
}
</style>
