<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #212529;
}
.p-field label {
    display:block;
}
.p-field {
    margin-bottom: 1rem;
}
</style>
