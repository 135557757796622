<template>
  <div class="grid">
    <div class="col-6">
      <h4>Название:</h4>
      <InputText style="width:10%;margin-right:2%;" class="mb-2" v-model="editableQuestionSku" @blur="updateQuestionSku" />
      <InputText style="width:88%;" class="mb-2" id="question-title" v-model="editableQuestionTitle" @blur="updateQuestionTitle" placeholder="Введите название вопроса" />
  <div class="flex items-center rotation-toggle mb-4">
        <Checkbox  v-model="rotation" @change="updateRotation" inputId="rotation" name="rotation"  binary   />
        <label for="rotation" class="ml-2" v-if="questionType != 'GRID'"> Ротация ответов</label>
        <label for="rotation" class="ml-2" v-else> Ротация рядов</label>
    </div>

  <!-- List of subquestions -->
  <div v-if="questionType === 'GRID'">
    <div v-if="subQuestions && !props.question.has_dynamic_rows">
      <h4>Строки:</h4>
        <ul class="answer-list">
          <li class="answer-item flex justify-content-between align-items-center" v-for="subQuestion in subQuestions" :key="subQuestion.id">
            <div class="answer-info flex align-items-center">
              <InputNumber v-model="subQuestion.order" showButtons buttonLayout="vertical" style="width: 2.2rem" :min="1" :max="subQuestion.length" @blur="updateSubOrder(subQuestion.id, subQuestion.order)">
                <template #incrementbuttonicon>
                  <span class="fa fa-plus" />
                </template>
                <template #decrementbuttonicon>
                  <span class="fa fa-minus" />
                </template>
              </InputNumber>
              <InputText v-model="subQuestion.question_sku" @blur="updateSubQuestionSku(subQuestion.id, subQuestion.title, subQuestion.question_sku)" class="ml-1 mr-1" style="width: 50px;" />
              <InputText v-model="subQuestion.title" @blur="updateSubQuestionHere(subQuestion.id, subQuestion.title)" style="width: 350px;" />
            </div>
            <div class="answer-actions">
              <i v-if="rotation" :class="['fa-solid', 'fa-rotate', { 'is_danger': subQuestion.is_rotatable }]" @click="toggleSubRotatable(subQuestion)" style="cursor: pointer;"></i>
              <Button @click="confirmSubQuestionDeletion(subQuestion.id)" icon="fa fa-trash" severity="link" class="p-button-danger" />
            </div>
          </li>
        </ul>
    </div>
    <div v-if="props.question.has_dynamic_rows" style="border-bottom: 1px solid #dedede;">
      <h4>Эта сетка имеет динамические ряды</h4>
      <p><b>Минимум рядов: </b>{{ props.question.min_rows }}</p>
      <p><b>Максимум рядов: </b>{{ props.question.max_rows }}</p>
      <p><b>Ответы из Справочника: </b>{{ getReferenceFileName(props.question.reference_file) || 'Не выбран' }} - {{ getColumnName(props.question.reference_column, props.question.reference_file) || 'Не выбрана' }}</p>
      
      <p>Ответы <span v-if="props.question.require_answers">необязательны</span><span v-else>обязательны</span></p>
    </div>
  </div>


  <!-- List of Answers -->
  <div v-if="answers.length > 0">
    <h4>Ответы:</h4>
    <ul class="answer-list">
      <li class="answer-item flex justify-content-between align-items-center" v-for="answer in answers" :key="answer.id">
        <div class="answer-info flex align-items-center">
          <InputNumber v-model="answer.order" showButtons buttonLayout="vertical" style="width: 2.2rem" :min="1" :max="answers.length" @blur="updateOrder(answer.id, answer.order)">
            <template #incrementbuttonicon>
              <span class="fa fa-plus" />
            </template>
            <template #decrementbuttonicon>
              <span class="fa fa-minus" />
            </template>
          </InputNumber>
          
          <!-- Editable text input for answer -->
          <div class="answer-title" style="margin-left:0rem;">
            <i v-if="answer.is_open" class="icon-types fa-regular fa-pen-to-square"></i>
            <i v-if="answer.is_none" class="icon-types fa-regular fa-circle-xmark"></i>
            <i v-if="(questionType === 'MULTIPLE' && !answer.is_open && !answer.is_none) || (questionType === 'GRID' && question.grid_type && question.grid_type == 'MULTIPLE'  && !answer.is_open && !answer.is_none)" class="icon-types fa-regular fa-square-check"></i>
            <i v-if="(questionType === 'REFERENCE' && !answer.is_open && !answer.is_none) || (questionType === 'GRID' && answer.answer_type === 'REFERENCE')" class="icon-types fa-solid fa-book"></i>
            <i v-if="(questionType === 'SINGLE' && !answer.is_open && !answer.is_none) || (questionType === 'GRID' && question.grid_type && question.grid_type == 'SINGLE'  && !answer.is_open && !answer.is_none)" class="icon-types fa-regular fa-circle-dot"></i>
            <InputText v-if="answer.min_value === null && answer.max_value === null" v-model="answer.answer_sku" @blur="updateAnswerSku(answer.id, answer.answer_sku)" style="width: 50px;margin-right: 15px;" />
            <InputText v-if="(answer.min_value === null && answer.max_value === null) && (questionType != 'REFERENCE')" style="width: 350px;" v-model="answer.title" @blur="updateAnswerFunc(answer.id, answer.title)" />
            <div v-if="answer.min_value != null && answer.max_value != null" class="flex align-items-center">
              <i class="icon-types  fa-solid fa-arrow-up-1-9"></i>
              <div class="flex align-items-center">
                <InputText v-model="answer.answer_sku" @blur="updateAnswerSku(answer.id, answer.answer_sku)" style="width: 50px;margin-right: 15px;" />
                <div>
                  <InputNumber disable showButtons v-model="answer.min_value" placeholder="Минимальное значение" @blur="updateAnswerFunc(answer.id, answer.title, answer.min_value, answer.max_value)" />
                  <InputNumber showButtons  v-model="answer.max_value" placeholder="Максимальное значение" @blur="updateAnswerFunc(answer.id, answer.title, answer.min_value, answer.max_value)" />
                </div>
              </div>
            </div>
            <!-- Выбор справочника и колонки -->
            <p v-if="(questionType === 'REFERENCE' && !answer.is_open && !answer.is_none)  || (questionType === 'GRID' && answer.answer_type === 'REFERENCE')"  style="display: inline;margin-left:37px;">{{ getReferenceFileName(answer.reference_file) || 'Не выбран' }} - {{ getColumnName(answer.reference_column, answer.reference_file) || 'Не выбрана' }} </p>
          </div>
        </div>
        <div class="answer-actions">
          <i v-if="rotation && questionType != 'GRID'" :class="['fa-solid', 'fa-rotate', { 'is_danger': answer.is_rotatable }]" @click="toggleRotatable(answer)" style="cursor: pointer;"></i>
          <i v-if="questionType === 'REFERENCE'" @click="addRefFilter(answer)" class="fa-solid fa-filter"></i>
          <Button @click="confirmAnswerDeletion(answer.id)" icon="fa fa-trash" severity="link" class="p-button-danger" />
        </div>
      </li>
    </ul>
  </div>
  <div v-else>
    <p>Ответов пока нет.</p>
  </div>

  <!-- Add new subquestion -->
  <div v-if="questionType === 'GRID'" class="mb-3">
    <!-- Логика для добавления ответов в сетке -->
    <div v-if="!props.question.has_dynamic_rows && !showDynamicRows">
      <h4>Добавить новую строку</h4>
      <InputText v-model="newSubQuestionTitle" placeholder="Введите текст подвопроса" style="width: 400px; margin-right: 10px;" />
      <Button label="Добавить строку" @click="addSubQuestion" :disabled="!newSubQuestionTitle" />
    </div>
    <div v-if="(!props.question.has_dynamic_rows && !showDynamicRows)" class="mt-2 mb-2">
      <Button label="Задать динамические строки" @click="activateDynamicRows" severity="secondary" class="mr-2" />
    </div>
  </div>

  <!-- Form for dynamic rows -->
  <div v-if="showDynamicRows" style="border: 1px solid #dedede;border-radius: 8px;padding:20px;margin-bottom:20px">
    <h4>Задать параметры динамических строк</h4>
    <div class="flex mt-3 mb-3">
      <InputNumber v-model="dynamicRows.min_rows" placeholder="мин кол-во строк" />
      <InputNumber v-model="dynamicRows.max_rows" placeholder="макс кол-во строк"  />
    </div>
    <div class="flex mt-3 mb-3">
      <Select v-model="dynamicRows.reference_file" :options="references" optionLabel="name" placeholder="Выберите справочник" />
      <Select v-model="dynamicRows.reference_column" :options="columns" optionLabel="label.name" placeholder="Выберите колонку" />
    </div>
    <div class="flex items-center rotation-toggle mb-4">
      <Checkbox v-model="dynamicRows.require_answers" name="require_answers" binary />
        <label for="require_answers" class="ml-2" > Ответы необязательны</label>
    </div>
    <div class="flex justify-content-between mt-3">
      <Button label="Отмена" @click="showDynamicRows = false" class="p-button-secondary" />
      <Button label="Сохранить" @click="saveDynamicRowsSettings" class="p-button-primary" />
    </div>
  </div>


    <!-- Add New Answer Form -->
    <h4>Добавить новый ответ</h4>
    <div v-if="questionType === 'REFERENCE'">
      <Select v-model="newAnswerReference" :options="references" optionLabel="name" placeholder="Выберите справочник" />
      <Select
        v-model="newAnswerColumn"
        :options="columns"
        optionLabel="label.name"
        placeholder="Выберите колонку"
      />
      <Button label="Добавить ответ" @click="addReferenceAnswer" :disabled="!newAnswerReference || !newAnswerColumn" />
    </div>
    <!-- Сложные ответов в сетке -->
    <div v-if="questionType === 'GRID' && question.grid_type === 'CUSTOM'" class="mt-2 mb-2">
      <!-- Кнопки для добавления типов ответов -->
      <Button v-if="!selectedCustomGridAnswerType" label="+ Scale" @click="selectCustomGridAnswerType('SCALE')" severity="secondary" class="mr-2" />
      <Button v-if="!selectedCustomGridAnswerType" label="+ Num" @click="selectCustomGridAnswerType('NUM')" severity="secondary" class="mr-2" />
      <Button v-if="!selectedCustomGridAnswerType" label="+ Справочник" @click="selectCustomGridAnswerType('REFERENCE')" severity="secondary" class="mr-2" />

      <!-- Отображение формы в зависимости от выбранного типа ответа -->
      <InputText v-if="selectedCustomGridAnswerType" style="width:400px;margin-right:10px;" id="new-answer-text" v-model="newAnswerText" placeholder="Введите текст ответа" />
      <div v-if="selectedCustomGridAnswerType === 'SCALE' || selectedCustomGridAnswerType === 'NUM'" class="mt-2 mb-2 flex align-center">
        <InputNumber showButtons v-model="newAnswerMin" placeholder="Мин" style="max-width:150px;margin-right:15px"/>
        <InputNumber showButtons v-model="newAnswerMax" placeholder="Макс" style="max-width:150px;margin-right:15px" />
      </div>

      <div v-if="selectedCustomGridAnswerType === 'REFERENCE'" class="mt-2 mb-2">
        <Select v-model="newAnswerReference" :options="references" optionLabel="name" placeholder="Выберите справочник" />
      <Select
        v-model="newAnswerColumn"
        :options="columns"
        optionLabel="label.name"
        placeholder="Выберите колонку"
      />
      </div>
      <Button v-if="selectedCustomGridAnswerType" label="Добавить колонку/ответ" @click="addCustomGridAnswer" />
      <Button v-if="selectedCustomGridAnswerType" label="Отмена" @click="resetCustomGridAnswerType" />
    </div>

    <div v-if="questionType === 'SINGLE' || questionType === 'MULTIPLE' || (questionType === 'GRID' && question.grid_type && (question.grid_type != 'SCALE') && (question.grid_type != 'CUSTOM'))">
      <InputText style="width:400px;margin-right:10px;" id="new-answer-text" v-model="newAnswerText" placeholder="Введите текст ответа" />
      <Button label="Добавить ответ" @click="addAnswer" :disabled="!newAnswerText.trim()" />
    </div>
    <div v-if="questionType === 'NUM' || questionType === 'SCALE' || (questionType === 'GRID' && question.grid_type && question.grid_type == 'SCALE')">
      <InputNumber showButtons v-model="newAnswerMin" placeholder="Мин" />
      <InputNumber showButtons v-model="newAnswerMax" placeholder="Макс" />
      <Button label="Добавить ответ" @click="addNumericAnswer" :disabled="!newAnswerMin || !newAnswerMax" />
    </div>
    <Button v-if="questionType === 'SINGLE' || questionType === 'NUM' || questionType === 'MULTIPLE' || questionType === 'SCALE'" label="+ открытый" @click="addOpenAnswer" severity="primary" class="mr-2 mt-4" />
    <Button v-if="questionType === 'SINGLE' || questionType === 'NUM' || questionType === 'MULTIPLE' || questionType === 'SCALE' || (questionType === 'GRID' && question.grid_type != 'CUSTOM') || questionType === 'REFERENCE'" label="+ None" @click="addNoneAnswer" severity="primary" class="mr-2 mt-4" />

    </div>
    <div class="col-5 col-offset-1">
      <h4>Правила элемента</h4>
      <RuleForm :elementId="props.elementId" :question="props.question" :key="ruleFormKey" :surveyId="props.surveyId"/>
    </div>
  </div>

  <!-- Confirmation Dialog -->
  <Dialog v-model:visible="showConfirmDialog" header="Подтверждение удаления" :modal="true" :closable="true">
    <p>Вы уверены, что хотите удалить этот ответ?</p>
    <div class="flex justify-content-between">
      <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
      <Button label="Удалить" @click="confirmAnswerDeletionConfirmed" class="p-button-danger" style="color:white;" />
    </div>
  </Dialog>

  <!-- Delete subquestion dialog -->
  <Dialog header="Подтверждение" v-model:visible="showDeleteSubquestion"  :modal="true" :closable="true">
  <p>Вы уверены, что хотите удалить этот подвопрос?</p>
  <div class="flex justify-content-between">
    <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
    <Button label="Удалить" @click="confirmSubQuestionDeletionConfirmed" class="p-button-danger" style="color:white;" />
  </div>
</Dialog>

    <!-- Модальное окно для выбора фильтра -->
    <Dialog v-model:visible="filterDialogVisible" header="Выбор фильтра" :modal="true" :closable="true">
      <template #footer>
        <Button label="Отмена" icon="pi pi-times" @click="closeFilterDialog" class="p-button-text" />
        <Button label="Применить" icon="pi pi-check" @click="applyRefFilter()" />
      </template>

      <div v-if="filterOptions">
        <h4>Выберите ответ для фильтрации:</h4>
        <div v-for="answer in filterOptions" :key="answer.answer_sku">
          <RadioButton :inputId="answer.answer_id" :value="answer.answer_id" v-model="selectedFilter" />
          <label :for="answer.answer_id" class="ml-2">{{ answer.question_sku }} - {{ answer.column_name }}</label>
        </div>
      </div>
      <div v-else>
        <p>Нет ни одного вопроса с ответом из этого справочника.</p>
      </div>
    </Dialog>

</template>

  
  <script setup>
  import { ref, defineProps, onMounted, watchEffect } from 'vue';
  import { getAnswers, createAnswer, updateElement, deleteAnswer, updateAnswerOrder, updateAnswer, getReferenceFiles, getSubQuestions, createSubQuestion, updateSubQuestion, deleteSubQuestion, updateSubQuestionOrder, getFilters, applyFilter } from '../../services/apiService';
  import RuleForm from './RuleForm.vue';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import Dialog from 'primevue/dialog';
  import InputNumber from 'primevue/inputnumber';
  import Select from 'primevue/select';
  import Checkbox from 'primevue/checkbox';
  import RadioButton from 'primevue/radiobutton';

  const ruleFormKey = ref(0);

  const props = defineProps({
    elementId: {
      type: Number,
      required: true
    },
    question: {
      type: Object,
      required: true
    },
    surveyId: {
      type: String,
    }
  });
  
  const answers = ref([]);
  const newAnswerText = ref('');
  const newAnswerMin = ref(null);
  const newAnswerMax = ref(null);
  const newAnswerReference = ref(null);
  const newAnswerColumn = ref(null);
  const editableQuestionTitle = ref(props.question.title);
  const editableQuestionSku = ref(props.question.question_sku); // добавляем sku
  const showConfirmDialog = ref(false);
  const subQuestionToDelete = ref(null)
  const showDeleteSubquestion = ref(false)
  const newSubQuestionTitle = ref(null)
  const subQuestions  = ref(null)
  const answerToDelete = ref(null);
  const rotation = ref(props.question.rotation);
  const questionType = ref(props.question.question_type);
  const references = ref([]);
  const columns = ref([]);
  const showDynamicRows = ref(false)

  const fetchAnswers = async () => {
    try {
      const question_id = props.question.id;
      const response = await getAnswers(question_id);
      answers.value = response.data;
      ruleFormKey.value += 1;
    } catch (error) {
      console.error('Error fetching answers:', error);
    }
  };
  
  const addAnswer = async () => {
    try {
      await createAnswer({
        question: props.question.id,
        title: newAnswerText.value
      });
      newAnswerText.value = ''; // Очистка поля ввода
      await fetchAnswers(); // Обновите список ответов
    } catch (error) {
      console.error('Error adding answer:', error);
    }
  };

  const addNumericAnswer = async () => {
  try {
    await createAnswer({
      question: props.question.id,
      min_value: newAnswerMin.value,
      max_value: newAnswerMax.value
    });
    newAnswerMin.value = null;
    newAnswerMax.value = null;
    await fetchAnswers(); // Обновите список ответов
  } catch (error) {
    console.error('Error adding numeric answer:', error);
  }
};
  
  const updateQuestionTitle = async () => {
    try {
      const data = {
        question_title: editableQuestionTitle.value
      };
      await updateElement(props.elementId, data);
    } catch (error) {
      console.error('Error updating question title:', error);
    }
  };
  
  const updateQuestionSku = async () => {
  try {
    const data = {
      question_sku: editableQuestionSku.value
    };
    await updateElement(props.elementId, data);
  } catch (error) {
    console.error('Error updating question SKU:', error);
  }
};

  const updateOrder = async (id, order) => {
    try {
      await updateAnswerOrder({ id, order });
      await fetchAnswers();
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };
  
  const updateAnswerFunc = async (id, title, min_value = null, max_value = null, is_rotatable) => {
  try {
    await updateAnswer(id, { title, min_value, max_value, is_rotatable });
    await fetchAnswers(); // Обновите список ответов после обновления
  } catch (error) {
    console.error('Error updating answer:', error);
  }
};
  
const updateAnswerSku = async (id, sku) => {
  try {
    await updateAnswer(id, { answer_sku: sku });
    await fetchAnswers(); // Обновите список ответов после обновления SKU
  } catch (error) {
    console.error('Error updating answer SKU:', error);
  }
};

  const confirmAnswerDeletion = (id) => {
    console.log('Delete button clicked for answer id:', id); // Отладочная печать
    answerToDelete.value = id;
    showConfirmDialog.value = true;
  };
  
  const confirmAnswerDeletionConfirmed = async () => {
    try {
      await deleteAnswer(answerToDelete.value);
      await fetchAnswers(); // Обновите список ответов после удаления
    } catch (error) {
      console.error('Error deleting answer:', error);
    } finally {
      showConfirmDialog.value = false;
      answerToDelete.value = null;
    }
  };
  
  const updateRotation = async () => {
  try {
    const data = {
      rotation: rotation.value
    };
    await updateElement(props.elementId, data);
  } catch (error) {
    console.error('Error updating rotation:', error);
  }
};

const toggleRotatable = async (answer) => {
  try {

    answer.is_rotatable = !answer.is_rotatable;
    await updateAnswerFunc(answer.id, answer.title, answer.min_value, answer.max_value, answer.is_rotatable);
  } catch (error) {
    console.error('Error toggling fixed state:', error);
  }
};

const addOpenAnswer = async () => {
  try {
    await createAnswer({
      question: props.question.id,
      is_open: true,
      title: 'Открытый ответ'
    });
    await fetchAnswers(); // Обновите список ответов
  } catch (error) {
    console.error('Error adding open answer:', error);
  }
};

const addNoneAnswer = async () => {
  try {
    await createAnswer({
      question: props.question.id,
      is_none: true,
      title: 'Ответ None'
    });
    await fetchAnswers(); // Обновите список ответов
  } catch (error) {
    console.error('Error adding none answer:', error);
  }
};

const addReferenceAnswer = async () => {
  try {
    const data = {
      question: props.question.id,
      reference_file_id: newAnswerReference.value.id,  // Сохраняем ID справочника
      reference_column_id: newAnswerColumn.value.value.id  // Сохраняем значение колонки
    };

    await createAnswer(data);
    newAnswerReference.value = null;
    newAnswerColumn.value = null;
    await fetchAnswers();  // Обновите список ответов
  } catch (error) {
    console.error('Error adding reference answer:', error);
  }
};

// Fetch reference files on component mount
const fetchReferenceFiles = async () => {
  try {
    const response = await getReferenceFiles();
    references.value = response.data;
  } catch (error) {
    console.error('Error fetching reference files:', error);
  }
};

const getReferenceFileName = (referenceFileId) => {
  const reference = references.value.find(ref => ref.id === referenceFileId);
  return reference ? reference.name : null;
};

const getColumnName = (columnId, referenceFileId) => {
  const selectedReference = references.value.find(ref => ref.id === referenceFileId);
  if (selectedReference) {
    const column = selectedReference.columns.find(col => col.id === columnId);
    return column ? column.name : null;
  }
  return null;
};


// Subquestions
const fetchSubQuestions = async () => {
  try {
    const response = await getSubQuestions(props.question.id);
    subQuestions.value = response.data;
  } catch (error) {
    console.error('Error fetching sub-questions:', error);
  }
};

const addSubQuestion = async () => {
  try {
    await createSubQuestion({
      question: props.question.id,
      title: newSubQuestionTitle.value,
      question_type: 'GRID'
    });
    newSubQuestionTitle.value = '';
    await fetchSubQuestions();
  } catch (error) {
    console.error('Error adding sub-question:', error);
  }
};

const updateSubQuestionHere = async (id, title) => {
  try {
    await updateSubQuestion(id, { title });
    await fetchSubQuestions();
  } catch (error) {
    console.error('Error updating sub-question:', error);
  }
};
const updateSubQuestionSku = async (id, title, question_sku) => {
  try {
    await updateSubQuestion(id, { title, question_sku });
    await fetchSubQuestions();
  } catch (error) {
    console.error('Error updating sub-question:', error);
  }
};

const toggleSubRotatable = async (question) => {
  try {

    question.is_rotatable = !question.is_rotatable;
    await updateSubQuestion(question.id, {title: question.title, is_rotatable: question.is_rotatable } );
  } catch (error) {
    console.error('Error toggling fixed state:', error);
  }
};

const confirmSubQuestionDeletion = (id) => {
  subQuestionToDelete.value = id;
  showDeleteSubquestion.value = true;
};

const confirmSubQuestionDeletionConfirmed = async () => {
  try {
    await deleteSubQuestion(subQuestionToDelete.value);
    await fetchSubQuestions();
  } catch (error) {
    console.error('Error deleting sub-question:', error);
  } finally {
    showDeleteSubquestion.value = false;
    subQuestionToDelete.value = null;
  }
};

const updateSubOrder = async (id, order) => {
  try {
    // Передаем объект с параметрами в API вызов
    await updateSubQuestionOrder(id, order);
    await fetchSubQuestions(); // Обновляем список подчинённых вопросов после успешного обновления
  } catch (error) {
    console.error('Error updating sub-question order:', error);
  }
};

// Custom Grids
const selectedCustomGridAnswerType = ref(null);

const selectCustomGridAnswerType = (type) => {
  selectedCustomGridAnswerType.value = type;
};

const resetCustomGridAnswerType = () => {
  selectedCustomGridAnswerType.value = null;
};

const addCustomGridAnswer = async () => {
  try {
    const answer_type = selectedCustomGridAnswerType.value;
    
    // Базовый объект для отправки на сервер
    const answerData = {
      question: props.question.id,
      answer_type: answer_type,
      title: newAnswerText.value,
    };

    // Добавляем свойства в зависимости от типа ответа
    if (answer_type === 'SCALE' || answer_type === 'NUM') {
      answerData.min_value = newAnswerMin.value;
      answerData.max_value = newAnswerMax.value;
    } else if (answer_type === 'REFERENCE') {
      answerData.reference_file_id = newAnswerReference.value.id;
      answerData.reference_column_id = newAnswerColumn.value.value.id;
    }

    await createAnswer(answerData);

    // Очистка полей
    newAnswerText.value = '';
    newAnswerMin.value = null;
    newAnswerMax.value = null;
    newAnswerReference.value = null;
    newAnswerColumn.value = null;
    selectedCustomGridAnswerType.value = null;

    await fetchAnswers(); // Обновите список ответов
  } catch (error) {
    console.error('Error adding answer:', error);
  }
};

// Dynamic Forms
const activateDynamicRows  = () => {
  showDynamicRows.value = true;
};
const dynamicRows = ref({
  min_rows: null,
  max_rows: null,
  require_answers: false,
  reference_file: null,
  reference_column: null
});

const saveDynamicRowsSettings = async () => {
  try {
    const data = {
      min_rows: dynamicRows.value.min_rows,
      max_rows: dynamicRows.value.max_rows,
      require_answers: dynamicRows.value.require_answers,
      reference_file_id: dynamicRows.value.reference_file.id,
      reference_column_id: dynamicRows.value.reference_column.value.id,
      has_dynamic_rows: true
    };
    await updateElement(props.elementId, data);
    showDynamicRows.value = false;
  } catch (error) {
    console.error('Error saving dynamic rows settings:', error);
  }
};

// For Reference Filters
const filterDialogVisible = ref(false);
const filterOptions = ref([]);
const selectedFilter = ref(null);

// Открыть диалоговое окно для фильтрации
const addRefFilter = async (answer) => {
  try {
    const ref_id = answer.reference_file
    // Получаем фильтры на основе выбранного справочника
    const response = await getFilters(ref_id);
    console.log(response)
    filterOptions.value = response;
    filterDialogVisible.value = true;
  } catch (error) {
    console.error('Error fetching filters:', error);
  }
};

// Закрыть диалоговое окно фильтрации
const closeFilterDialog = () => {
  filterDialogVisible.value = false;
};

// Применить фильтр
const applyRefFilter = async () => {
  try {
    await applyFilter(selectedFilter.value);
    // Обновить список ответов или выполнить другие действия после применения фильтра
  } catch (error) {
    console.error('Error applying filter:', error);
  } finally {
    closeFilterDialog();
  }
};


// Watch for changes in selected reference file
watchEffect(() => {
  if (newAnswerReference.value) {
    columns.value = newAnswerReference.value.columns.map((column) => ({
      label: column,
      value: column
    }));
  } else {
    columns.value = [];
  }
});
watchEffect(() => {
  if (dynamicRows.value.reference_file) {
    columns.value = dynamicRows.value.reference_file.columns.map((column) => ({
      label: column,
      value: column
    }));
  } else {
    columns.value = [];
  }
});

///////
onMounted(async () => {
  await fetchAnswers();
  await fetchSubQuestions();
  await fetchReferenceFiles();
});
  </script>
  

  
  <style scoped>
  h4 {
    margin: 0 0 5px 0;
    font-weight: 500;
  }
.answer-list {
  list-style: none;
  padding: 0;
  margin:0;
}
.answer-item {
  /* border: 1px solid #ccc; */
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sub-question-item {
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.p-button-danger {
  color: #dc3545!important;
}
.fa-rotate.is_danger {
  color: #dc3545;
}
.answer-title span.p-inputnumber{

display: block;
margin-bottom: 5px;
}
.answer-title span.p-inputtext {
width:50px;
}
.icon-types {
  color: var(--p-form-field-border-color);
  font-size: 22px;
  margin: 0 10px;
  vertical-align: middle;
}
.answer-actions i, .answer-actions button {
  color: var(--p-button-link-color);
  font-size:20px;
}
  </style>
  