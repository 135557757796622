import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';
import router from './router';
import axios from 'axios';
import 'primeflex/primeflex.css';

// Создание пользовательского пресета
const MyPreset = definePreset(Aura, {
    semantic: {
        primary: {
            50: '#e0f0f6',   // Lightest Shade
            100: '#b3d0e4',
            200: '#80a9c7',
            300: '#4d83aa',
            400: '#316f93',
            500: '#2d588b',  // Main Primary Color
            600: '#274a71',
            700: '#1f3a58',
            800: '#172a40',
            900: '#0f1b29',
            950: '#002133'   // Darkest Shade
        }
    }
});

const app = createApp(App);

app.config.globalProperties.$http = axios;

app.use(PrimeVue, {
    theme: {
        preset: MyPreset,
        options: {
            darkModeSelector: '.fake-dark-selector'
        }
    }
});

if (router) {
    app.use(router);
}

app.mount('#app');
