<template>
  <div class="col-6 p-0 survey-detail">
    <h3>Экраны Опроса "<span style="color:var(--p-button-link-color)">{{ survey.title }}</span>" <span >({{ survey.survey_id }})</span></h3>
 
    <!-- Create Screen Button -->
    <div class="flex justify-content-between">
      <Button label="К опросам" @click="goBack" class="p-button-secondary" />
      <Button label="Добавить экран" icon="fa fa-plus" @click="showCreateScreenDialog = true" />
    </div>
       
    <!-- Screens List -->
    <ul class="screen-list" v-if="screens.length > 0">
      <li v-for="screen in screens" :key="screen.id" class="screen-item flex justify-content-between align-items-center">
        <div class="screen-info flex align-items-center">
          <InputNumber v-model="screen.order" showButtons buttonLayout="vertical" style="width: 2.2rem" :min="1" :max="99"  @blur="updateOrder(screen.id, screen.order)" >
          <template #incrementbuttonicon>
              <span class="fa fa-plus" />
          </template>
          <template #decrementbuttonicon>
              <span class="fa fa-minus" />
          </template>
        </InputNumber>
        <div class="ml-2">
          <router-link :to="`/survey/${survey.id}/screen/${screen.id}`">{{ screen.title }}</router-link>
          <span class="m-0 subtext">Элементов: {{ screen.element_count }}</span>
        </div>  
      </div>
        <div class="screen-actions">
          <Button @click="openEditScreenDialog(screen)" icon="fa fa-edit" severity="link" />
          <Button @click="confirmScreenDeletion(screen.id)" icon="fa fa-trash" severity="link" class="p-button-danger" />
        </div>
      </li>
    </ul>
    <ul v-else class="p-0" style="list-style: none;">
      <li class="p-0">Экранов у этого опроса ещё нет</li>
    </ul>

    <!-- Create Screen Dialog -->
    <Dialog 
      v-model:visible="showCreateScreenDialog" 
      header="Создать экран" 
      :modal="true" 
      :closable="false"
    >
      <div class="p-field">
        <label for="screen-title">Название экрана</label>
        <InputText id="screen-title" v-model="newScreen.title" />
      </div>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showCreateScreenDialog = false"  class="p-button-secondary" />
        <Button label="Создать" @click="screenCreation"  />
      </div>
    </Dialog>

    <!-- Edit Screen Dialog -->
    <Dialog 
      v-model:visible="showEditScreenDialog" 
      header="Редактировать экран" 
      :modal="true" 
      :closable="false"
    >
      <div class="p-field">
        <label for="edit-screen-title">Название экрана</label>
        <InputText id="edit-screen-title" v-model="editScreen.title" />
      </div>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showEditScreenDialog = false" class="p-button-secondary" />
        <Button label="Сохранить" @click="screenUpdate"  />
      </div>
    </Dialog>

    <!-- Confirmation Dialog -->
    <Dialog 
      v-model:visible="showConfirmDialog" 
      header="Подтверждение удаления" 
      :modal="true" 
      :closable="false"
    >
      <p>Вы уверены, что хотите удалить этот экран?</p>
      <div class="flex justify-content-between">
        <Button label="Отмена" @click="showConfirmDialog = false" class="p-button-secondary" />
        <Button label="Удалить" @click="confirmScreenDeletionConfirmed" class="p-button-danger" style="color:white;" />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getSurvey, getScreens, createScreen, deleteScreen, updateScreen, updateScreenOrder } from '../../services/apiService';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';

const survey = ref({});
const screens = ref([]);
const route = useRoute();
const router = useRouter();

const showCreateScreenDialog = ref(false);
const showEditScreenDialog = ref(false);
const showConfirmDialog = ref(false);
const screenToDelete = ref(null);
const newScreen = ref({ title: '', position: 0 });
const editScreen = ref({ id: '', title: '', position: 0 });

const fetchSurvey = async () => {
  try {
    const surveyId = route.params.id;
    if (!surveyId) throw new Error('Survey ID is not provided');
    const response = await getSurvey(surveyId);
    survey.value = response.data;
    await fetchScreens(surveyId);
  } catch (error) {
    console.error('Error fetching survey:', error);
  }
};

const fetchScreens = async (surveyId) => {
  try {
    const response = await getScreens(surveyId);
    screens.value = response.data;
  } catch (error) {
    console.error('Error fetching screens:', error);
  }
};

const screenCreation = async () => {
  try {
    // Добавляем ID опроса в данные нового экрана
    const screenData = {
      title: newScreen.value.title,
      survey: survey.value.id // Передаем ID опроса
    };
    await createScreen(screenData);
    await fetchScreens(route.params.id);
  } catch (error) {
    console.error('Error creating screen:', error);
  } finally {
    showCreateScreenDialog.value = false;
    newScreen.value = { title: '' }; // Очищаем форму
  }
};

const openEditScreenDialog = (screen) => {
  editScreen.value = { ...screen };
  showEditScreenDialog.value = true;
};

const screenUpdate = async () => {
  try {
    await updateScreen(editScreen.value.id, editScreen.value);
    await fetchScreens(route.params.id);
  } catch (error) {
    console.error('Error updating screen:', error);
  } finally {
    showEditScreenDialog.value = false;
    editScreen.value = { id: '', title: '', position: 0 };
  }
};

const confirmScreenDeletion = (id) => {
  screenToDelete.value = id;
  showConfirmDialog.value = true;
};

const confirmScreenDeletionConfirmed = async () => {
  try {
    await deleteScreen(screenToDelete.value);
    await fetchScreens(route.params.id);
  } catch (error) {
    console.error('Error deleting screen:', error);
  } finally {
    showConfirmDialog.value = false;
    screenToDelete.value = null;
  }
};

const updateOrder = async (id, order) => {
  try {
    await updateScreenOrder(id, order);
    await fetchScreens(route.params.id);
  } catch (error) {
    console.error('Error:', error);
  }
};

const goBack = () => {
  router.push('/surveys');
};

onMounted(fetchSurvey);
</script>

<style scoped>
.survey-detail {
  padding: 20px;
}
.screen-list {
  list-style: none;
  padding: 0;
}
.screen-item {
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.screen-actions {
  display: flex;
}
.p-button-danger {
  color: #dc3545;
}
.screen-item a {
  text-decoration: none;
    color: #007bff;
    font-weight: 600;
}
.subtext {
  display: block;
  color: #6c757d;
  font-size: 0.7rem !important;
}
</style>
