<template>
    <Dialog v-model:visible="dialogVisible" header="Создать опрос" modal>
        <div class="p-fluid">
            <div class="p-field">
                <label for="title">Название</label>
                <InputText id="title" v-model="survey.title" />
            </div>
            <div class="p-field">
                <label for="survey_id">ID опроса</label>
                <InputText id="survey_id" v-model="survey.survey_id" />
            </div>
            <div class="p-field">
                <Button label="Создать" @click="createSurvey" />
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref, watch } from 'vue';
import { defineEmits, defineProps } from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { createSurvey as createSurveyApi } from '../../services/apiService';

const props = defineProps(['visible']);
const emit = defineEmits(['update:visible', 'surveyCreated']);

const survey = ref({
    title: '',
    survey_id: ''
});

const dialogVisible = ref(props.visible);

watch(() => props.visible, (newVal) => {
    dialogVisible.value = newVal;
});

const createSurvey = async () => {
    try {
        await createSurveyApi(survey.value);
        emit('surveyCreated');
        emit('update:visible', false);
    } catch (error) {
        console.error('Error creating survey:', error);
    }
};

const closeDialog = () => {
    emit('update:visible', false);
};

watch(dialogVisible, (newVal) => {
    if (!newVal) {
        closeDialog();
        survey.value = { title: '', survey_id: '' };
    }
});
</script>

<style scoped>
label {
    display:block;
}
.p-field {
    margin-bottom: 1rem;
}
</style>
